import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '@/store/index'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'ZyHome',
  //   component: () => import('../views/zyHome.vue'),
  //   meta: {
  //     title: '首页'
  //   }
  // },
  {
    path: '/mlogin',
    name: 'MLogin',
    component: () => import('../views/zyHome.vue'),
    meta: {
      title: '首页'
    }
  },
  {
    path: '/teacher',
    name: 'Teacher',
    component: () => import('../views/Auth.vue'),
    meta: {
      title: '跳转中...'
    }
  },
  {
    path: '/student/:classId',
    name: 'Student',
    component: () => import('../views/Auth.vue'),
    meta: {
      title: '跳转中...'
    }
  },
  {
    path: '/T',
    name: 'T',
    component: () => import('../views/Auth.vue'),
    meta: {
      title: '跳转中...'
    }
  },
  {
    path: '/S/:classId',
    name: 'S',
    component: () => import('../views/Auth.vue'),
    meta: {
      title: '跳转中...'
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      title: '身份验证'
    }
  },
  {
    path: '/zyList',
    name: 'ZyList',
    meta: {
      title: '练习管理'
    },
    component: () => import('../views/zyList.vue')
  },
  {
    path: '/zyInfo',
    name: 'ZyInfo',
    meta: {
      title: ''
    },
    component: () => import('../views/zyInfo.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

let isLogin = false

router.beforeEach(async (to, from, next) => {
  // 未授权过
  if (to.name == 'Teacher' || to.name == 'Student') {
    window.sessionStorage.removeItem('s_t_model')
    window.sessionStorage.removeItem('classId')
  }
  if (to.name == 'MLogin') {
    isLogin = true
    next()
  } else if (!window.sessionStorage.getItem('s_t_model')) {
    if (['T', 'S'].includes(to.name)) {
      const url = window.location.href
      const code = url.split('code=')[1].split('&state')[0]
      let obj = { code, type: to.name == 'T' ? 2 : 1 }
      if (obj.type === 1) obj.classId = to.params.classId
      store.dispatch('accestionFun', obj).then(async res => {
        isLogin = true
        if (res == '/zyList' && obj.type === 2) {
          console.log(2);
          await store.dispatch('getClassList')
        }
        next(res || '')
      })
    } else {
      console.log('进入微信授权')
      window.sessionStorage.setItem('classId', to.params.classId)
      await store.dispatch('loginAuth', to.name == 'Teacher' ? 'T' : 'S/' + to.params.classId)
    }
  } else {
    if (!isLogin && window.sessionStorage.getItem('s_t_model') == 2) {
      await store.dispatch('getClassList').then(res => {
        isLogin = true
        next(res)
      })
    } else {
      isLogin = true
    }
    if (['Teacher', 'Student', 'S', 'T'].includes(to.name)) {
      next('/zyList')
    } else {
      next()
    }
  }
})

router.afterEach((to, from) => {
  if (to.name === 'ZyInfo') {
    if (to.query.id) document.title = '编辑练习'
    else document.title = '布置练习'
  } else {
    document.title = to.meta.title
  }
})

export default router