import axios from 'axios'
import { Toast } from 'vant';
import store from '@/store'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_API, // url = base url + request url
  timeout: 30 * 1000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.state === 200) { 
      return res
    } else {
      Toast.fail({
        message: res.message || 'Error',
        duration: 5 * 1000
      }) 
      return Promise.reject(new Error(res.msg || 'Error'))
    }
  },
  error => {
    console.log(error.response)
    if (error.response.status === 401) {
      const type = window.sessionStorage.getItem('s_t_model')
      window.sessionStorage.removeItem('s_t_model')
      store.dispatch('loginAuth', type == 2 ? 'T' : 'S/' + window.sessionStorage.getItem('classId'))
      return Promise.reject(error)
    } else {
      Toast.fail({
        message:  error.response.message || error.response.message || error.message,
        duration: 5 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export default service
