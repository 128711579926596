<template>
  <div class="home common">
    <div class="home-contain">
      <img class="h-img" src="@/assets/images/h.png" alt="">
      <div class="search">
        <input v-model="keyWord" type="text" class="search-input" :placeholder="isBlur ? '' : placeholder" @focus="handleBlur(true)" @blur="handleBlur(false)">
        <van-icon name="close" class="close-icon" v-show="keyWord" @click="handleClear" />
      </div>
      <div class="btns">
        <van-button type="info" class="yz-btn" @click="handleYz">立即验证</van-button>
      </div>
    </div>
    <!-- <div id="login_container"></div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { authApi } from '@/api'
import { Toast } from 'vant'

// @ is an alias to /src
export default {
  name: 'Home',
  data() {
    return {
      keyWord: '',
      isBlur: false
    }
  },
  computed: {
    ...mapState({
      classColumns: state => state.classList,
      placeholder: state => {
        return state.s_t_model == 2 ? '请输入您本人的姓名' : '请输入您孩子的姓名'
      }
    })
  },
  methods: {
    handleClear() {
      this.keyWord = ''
    },
    handleBlur(val) {
      this.isBlur = val
    },
    handleYz() {
      if (!this.keyWord) {
        Toast.fail('请输入姓名！')
        return
      }
      authApi({ name: this.keyWord }).then(async res => {
        await this.$store.dispatch('getClassList')
        this.$router.push({
          path: './zyList'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  align-items: center;
  justify-content: center;
  padding: 2.4rem;
  box-sizing: border-box;
  .home-contain {
    width: 100%;
    text-align: center;
    margin-top: -3rem;
    .h-img {
      width: 6.25rem;
      height: auto;
      margin-bottom: 3rem;
    }
    .search {
      width: 100%;
      margin-bottom: 2rem;
      position: relative;
      .search-input {
        width: 100%;
        border: 1px solid #d7d7d7;
        padding: 1rem 1.5rem;
        box-sizing: border-box;
        height: 3.125rem;
        border-radius: 3.5rem;
        text-align: center;
        outline: none;
        background: none;
        appearance: none;
      }
      .search-input::placeholder {
        color: #999999;
      }
      .close-icon {
        position: absolute;
        right: 0.5rem;
        top: 50%;
        transform: translate(0, -50%);
        color: #999999;
      }
    }
    .btns {
      .yz-btn {
        background: #2579fc;
        width: 100%;
        border-radius: 3.5rem;
        height: 3.125rem;
      }
    }
  }
}
</style>