import request from '../request.js'

export function demoLogin(type, id) {
  return request({
    url: `/testLogin/${type}/${id}`,
    method: 'get'
  })
}
export function demoList() {
  return request({
    url: `/teacher/classList`,
    method: 'get'
  })
}
// 班级下拉框数据
export function classListApi() {
  return request({
    url: `/teacher/classList`,
    method: 'get'
  })
}
// 列表数据
export function listApi(data) {
  return request({
    url: `/teacher/list`,
    method: 'post',
    data
  })
}
// 详情
export function detailApi(id) {
  return request({
    url: `/teacher/homeworkDetail/${id}`,
    method: 'get'
  })
}
// 添加编辑详情
export function addDetailApi(data, method) {
  return request({
    url: `/teacher/homework`,
    method,
    data
  })
}
// 删除详情
export function delDetailApi(id) {
  return request({
    url: `/teacher/homework/${id}`,
    method: 'delete'
  })
}

// code登录
export function codeLoginApi(type, params) {
  return request({
    url: `/login/${type}`,
    method: 'get',
    params
  })
}

// 绑定登录人
export function authApi(params) {
  return request({
    url: `/auth`,
    method: 'get',
    params
  })
}

// 学生列表数据
export function sLlistApi(data) {
  return request({
    url: `/student/list`,
    method: 'post',
    data
  })
}
