import Vue from 'vue'
import Vuex from 'vuex'
import { classListApi, codeLoginApi } from '@/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    host: window.location.origin + '/#/',
    classList: [],
    s_t_model: window.sessionStorage.getItem('s_t_model') || 1 // 1家长，2老师
  },
  mutations: {
    SET_CLASSLIST(state, classList) {
      state.classList = classList
    },
    SET_MODE(state, s_t_model) {
      state.s_t_model = s_t_model
    }
  },
  actions: {
    getClassList({ commit }) {
      return new Promise((resolve, reject) => {
        classListApi().then(async res => {
          if (res.data) {
            await commit('SET_CLASSLIST', res.data.map(item => {
              return {
                id: item.classId,
                text: item.className,
                subjectList: item.subjectList.map(it => {
                  return {
                    id: it.id,
                    text: it.subjectName
                  }
                })
              }
            }))
            resolve()
          }
        }).catch(err => {
          console.log(err)
          reject()
        })
      })
    },
    // 登录
    loginAuth({ commit, state }, val) {
      return new Promise(resolve => {
        const host = 'https://open.weixin.qq.com/connect/oauth2/authorize' + '?'
        const appid = 'appid=wxb4c8ea2a38147a75' + '&'
        console.log(state.host + val)
        const redictUrl = 'redirect_uri=' + encodeURIComponent(state.host + val) + '&'
        const responseType = 'response_type=code' + '&'
        const scope = 'scope=snsapi_base' + '&'
        const urlState = 'state=STATE#wechat_redirect'
        const url = host + appid + redictUrl + responseType + scope + urlState
        window.location.replace(url)
        // window.localStorage.setItem('isSession', 1) // session是否失效 0失效，1未失效
        // window.localStorage.setItem('isAuth', 1) // 用户授权 0 未授权，1已授权
        resolve()
      })
    },

    accestionFun({ commit }, obj) {
      return new Promise(resolve => {
        window.sessionStorage.setItem('s_t_model', obj.type)
        commit('SET_MODE', obj.type)
        codeLoginApi(obj.type, obj).then(res => {
          console.log(res)
          if (!res.data) {
            // window.localStorage.setItem(obj.type === 2 ? 'Tlogin' : 'Slogin', 1)
            resolve('/home')
          } else {
            resolve('/zyList')
          }
        })
      })
    }

  }
})
